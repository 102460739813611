<template>
  <div :class="$style.wrap" class="grid justify-content-between">
    <form :class="$style.form" class="_relative col mt-4" @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.default_background'" />
        <div class="col" :class="$style.settingValue">
          <div :class="$style.bgBox" :style="`background-image: url(${previewBG.base_url});`">
            <button @click="() => openModal('bg')" type="button" :class="$style.bgAction">
              <Icon name="edit" :className="$style.bgBoxIcon" />
            </button>
          </div>
        </div>
      </div>
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.background_type'" />
        <div class="col" :class="$style.settingValue">
          <Dropdown
            v-model="settings.background_type"
            :options="optionsBG"
            optionLabel="label"
            optionValue="value"
            class="_full-width"
            :disabled="previewBG.company_id === 1"
          />
        </div>
      </div>
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.default_logo'" />
        <div class="col" :class="$style.settingValue">
          <div :class="$style.bgBox" :style="`background-image: url(${previewLogo.url});`">
            <button @click="() => openModal('logos')" type="button" :class="$style.bgAction">
              <Icon name="edit" :className="$style.bgBoxIcon" />
            </button>
            <button @click="() => removeImage('logos')" type="button" :class="$style.bgAction" v-if="previewLogo.url">
              <Icon name="trash" :className="$style.bgBoxIcon" />
            </button>
          </div>
        </div>
      </div>
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.logo_position'" />
        <div class="col" :class="$style.settingValue">
          <Dropdown
            v-model="settings.logo_position"
            :options="optionsLogoPosition"
            optionLabel="label"
            optionValue="value"
            class="_full-width"
            :disabled="!previewLogo.url"
          />
        </div>
      </div>
      <!-- <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.license_plate_image'" />
        <div class="col"></div>
      </div> -->
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.default_banner'" />
        <div class="col" :class="$style.settingValue">
          <div :class="$style.bgBox" :style="`background-image: url(${previewBanenr.url});`">
            <button @click="() => openModal('banners')" type="button" :class="$style.bgAction">
              <Icon name="edit" :className="$style.bgBoxIcon" />
            </button>
            <button @click="() => removeImage('banners')" type="button" :class="$style.bgAction" v-if="previewBanenr.url">
              <Icon name="trash" :className="$style.bgBoxIcon" />
            </button>
          </div>
        </div>
      </div>
      <div class="grid mb-3 align-items-center">
        <div class="col" v-t="'settings_page.default_preset'" />
        <div class="col" :class="$style.settingValue">
          <Dropdown
            v-model="settings.preset_id"
            :options="optionsPreset"
            optionLabel="name"
            optionValue="id"
            class="_full-width"
          />
        </div>
      </div>
    </form>

    <div :class="$style.switches" class="col mt-4">
      <div class="grid flex align-items-center mb-3">
        <div class="col" v-t="'settings_page.cutting'" />
        <div class="col flex align-items-center">
          <div class="inline-flex align-items-center mr-3" @click="() => helper = 'cutting'">
            <Icon name="info" :className="$style.info" />
          </div>
          <InputSwitch v-model="settings.cutting" />
        </div>
      </div>
      <div class="grid flex align-items-center mb-3">
        <div class="col" v-t="'settings_page.licence_plate_blur'" />
        <div class="col flex align-items-center">
          <div class="inline-flex align-items-center mr-3" @click="() => helper = 'blur'">
            <Icon name="info" :className="$style.info" />
          </div>
          <InputSwitch v-model="settings.plate_blur" @change="() => handlingRelatedSwitches('blur')" />
        </div>
      </div>
      <div class="grid flex align-items-center mb-3">
        <div class="col" v-t="'settings_page.licence_plate_image'" />
        <div class="col flex align-items-center">
          <div class="inline-flex align-items-center mr-3" @click="() => helper = 'plate'">
            <Icon name="info" :className="$style.info" />
          </div>
          <InputSwitch v-model="settings.plate_image" @change="() => handlingRelatedSwitches('image')" />
        </div>
      </div>
    </div>
  </div>
  <Button :class="$style.action" class="p-button-big _full-width mt-5" @click="submit"><span v-t="'save_change'"></span></Button>

  <Dialog :class="$style.modal" v-model:visible="isShow" :modal="true" :draggable="false">
    <div >
      <Toolbar class="p-4">
        <template #left>
          <p class="_heading-2"><span v-t="'available_options'" /></p>
        </template>
        <template #right>
          <Button @click="isShow = false" class="p-button-outlined p-button-gray p-button-rounded p-button-only-icon">
            <Icon name="close" />
          </Button>
        </template>
      </Toolbar>
      <div class="px-4 pb-5">
        <Empty v-if="!$store.state.settings[imagesListKey].data.length" />
        <div class="grid">
          <div
            class="col-3"
            v-for="item in $store.state.settings[imagesListKey].data"
            :key="item.id"
            @click="() => setImage(item.id)">
            <button :class="$style.modalImgBox">
              <img :src="`${item.url || item.base_url}`" :class="$style.modalImg" alt="">
              <Icon name="plus" :className="$style.modalIcon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <Dialog :class="$style.modal" v-model:visible="helper" :modal="true" :draggable="false">
    <div>
      <Toolbar class="p-4">
        <template #left>
          <p class="_heading-2"><span v-t="'settings_page.demo_result'" /></p>
        </template>
        <template #right>
          <Button @click="helper = null" class="p-button-outlined p-button-gray p-button-rounded p-button-only-icon">
            <Icon name="close" />
          </Button>
        </template>
      </Toolbar>
      <div :class="$style.helperWrap" class="px-4 pb-5 flex">
        <div :class="$style.helperBox">
          <div :class="$style.helperTitle" v-t="'before'" />
          <img src="../../assets/img/reference.jpg" alt="">
        </div>
        <div :class="$style.helperBox">
          <div :class="$style.helperTitle" v-t="'after'" />
          <img :src="helperImage[helper]" alt="">
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';

import { Icon, Empty } from '../../components/ui'

import referenceCutting from '../../assets/img/reference_cutting.jpg'
import referenceBlur from '../../assets/img/reference_cutting.jpg'
import referencePlate from '../../assets/img/reference_cutting.jpg'

export default {
  components: {
    Icon,
    Dropdown,
    InputSwitch,
    Button,
    Dialog,
    Toolbar,
    Icon,
    Empty,
  },
  setup() {
    const store = useStore()
    const isShow = ref()
    const helper = ref()
    const { t } = useI18n()
    const toast = useToast()
    const imagesListKey = ref()

    const settings = computed(() => store.state.account.data.settings || {})
    const optionsPreset = computed(() => store.state.settings.presets.data || [])

    const previewBG = computed(() => store.state.settings.bg.data.find(({ id }) => id === settings.value.background_id) || {})
    const previewLogo = computed(() => store.state.settings.logos.data.find(({ id }) => id === settings.value.logo_id) || {})
    const previewBanenr = computed(() => store.state.settings.banners.data.find(({ id }) => id === settings.value.banner_id) || {})

    const helperImage = {
      cutting: referenceCutting,
      blur: referenceBlur,
      plate: referencePlate,
    }

    const optionsCrop = [
      { value: 'Full', label: 'Full crop' },
      { value: 'Half', label: 'Half crop' },
      { value: 'Full with blur', label: 'Blur crop' },
      { value: 'Half with blur', label: 'Half blur crop' },
    ]

    const optionsBG = [
      { value: 'Own', label: 'Own' },
      { value: 'Base', label: 'Base' },
      { value: 'Photo Box', label: 'Photo Box' },
    ]

    const optionsLogoPosition = [
      { value: 'Top Left', label: 'Top left' },
      { value: 'Bottom Left', label: 'Bottom left' },
      { value: 'Top Right', label: 'Top right' },
      { value: 'Bottom Right', label: 'Bottom right' },
    ]

    function setImage(id) {
      isShow.value = false
      switch (imagesListKey.value) {
        case 'logos':
          settings.value.logo_id = id
          break;
        case 'banners':
          settings.value.banner_id = id
          break;
        default:
          settings.value.background_id = id
          if (previewBG.value.company_id) {
            settings.value.background_type = 'Own'
          }
          break;
      }
    }
    function removeImage(type) {
      switch (type) {
        case 'logos':
          settings.value.logo_id = null
          break;
        case 'banners':
          settings.value.banner_id = null
          break;
      }
    }

    function openModal(content) {
      isShow.value = true
      imagesListKey.value = content
    }

    function submit() {
      const body = {
        name: store.state.account.data.name,
        email: store.state.account.data.email,
        phone: store.state.account.data.phone,
        default_crop: store.state.account.data.settings.default_crop,
        banner_id: store.state.account.data.settings.banner_id,
        background_id: store.state.account.data.settings.background_id,
        logo_id: store.state.account.data.settings.logo_id,
        background_type: store.state.account.data.settings.background_type,
        logo_position: store.state.account.data.settings.logo_position,
        licence_plate_id: store.state.account.data.settings.licence_plate_id,
        preset_id: store.state.account.data.settings.preset_id,
        cutting: store.state.account.data.settings.cutting,
        plate_blur: store.state.account.data.settings.plate_blur,
        plate_image: store.state.account.data.settings.plate_image,
        polishing: store.state.account.data.settings.polishing,
        rim_polishing: store.state.account.data.settings.rim_polishing,
        reflective_floor: store.state.account.data.settings.reflective_floor,
      }

      store.dispatch('account/sendBase', { body, toast, t })
    }

    function handlingRelatedSwitches(param) {
      switch (param) {
        case 'blur':
          settings.value.plate_image = false
          break;
        case 'image':
          settings.value.plate_blur = false
          break;
      }
    }

    return {
      isShow,
      helper,
      optionsCrop,
      imagesListKey,
      optionsBG,
      previewBG,
      optionsLogoPosition,
      previewLogo,
      previewBanenr,
      optionsPreset,
      settings,
      submit,
      openModal,
      setImage,
      handlingRelatedSwitches,
      removeImage,
      helperImage,
    }
  }
}
</script>

<style lang="scss" module>
  .wrap {
    max-width: 935px;
  }
  .form {
    max-width: 424px;
    width: 50%;
  }
  .switches {
    max-width: 373px;
    width: 50%;
  }
  .settingValue {
    width: 200px;
    flex-basis: 200px;
    flex-grow: 0;
  }
  .action {
    max-width: 424px;
    width: 100%;
  }

  .info {
    @include anim(fill);
    fill: $text_color_light_gray;

    &:hover {
      fill: $primary_red
    }
  }

  .bgBox {
    width: 100%;
    margin-left: auto;
    height: 100px;
    border-radius: $radius;
    background-position: center;
    background-size: crop;
    position: relative;
    background-color: $text_color_line_gray;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .bgAction {
    @include anim(opacity);
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(#fff, 0.5);
    padding: 8px 8px 6px 8px;

    &:first-child {
      border-radius: 0 0 0 6px;
    }

    &:hover {
      opacity: .5;
    }
  }

  .bgBoxIcon {
    fill: $text_color_black;
  }

  .modal {
    background-color: #fff;
    border-radius: $radius;
    max-width: 860px;
    width: 100%;
  }

  .modalImgBox {
    border-radius: $radius;
    border: 1px solid $text_color_line_gray;
    height: 100%;
    position: relative;

    &:before {
      @include anim(opacity);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.5);
      opacity: 0;
    }

    &:hover {
      .modalIcon,
      &:before {
        opacity: 1;
      }
    }
  }

  .modalImg {
    border-radius: $radius;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .modalIcon {
    @include anim(opacity);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    opacity: 0;
    fill: $text_color_black;
  }

  .helperBox {
    position: relative;
    img {
      max-width: 100%;
    }

    &:first-child {
      img {
        border-radius: $radius 0 0 $radius;
      }
    }
    &:last-child {
      img {
        border-radius: 0 $radius $radius 0;
      }
    }
  }

  .helperTitle {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    background-color: $primary_dark;
    border-radius: $radius-small;
    padding: 4px 10px;
    font-size: 14px;
  }
</style>